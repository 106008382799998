import { trpc } from '../../../utils/trpc'
import { raffleMinType } from '../../techRaffles/types'

export function useParticipantDataForSingleRaffleId({
  userId,
  raffleId,
  disabled
}: {
  userId?: string
  raffleId: string
  disabled?: boolean
}) {
  return trpc.useQuery(['user.getParticipantDataForSingleRaffle', {
    raffleId,
    userId: userId ?? ''
  }], {enabled: !!userId && !disabled, staleTime: 30000 })
}

export function useParticipantsForRaffle({
  raffleId,
  enabled
}: {raffleId: string, enabled: boolean}) {
  return trpc.useQuery(['raffle.participantsPerRaffle', {
    raffleId: raffleId,
  }], {enabled: !!raffleId && enabled})
}

export function useParticipantDataForMultipleRaffleIds({
  userId,
  raffleIds
}: {
  userId?: string
  raffleIds: string[]
}) {
  return trpc.useQuery(['user.getParticipantDataForMultipleRaffles', {
    raffleIds,
    userId: userId ?? ''
  }], {enabled: !!userId})
}

export function useParticipantDataForMultipleRaffles({
  userId,
  raffles
}: {
  userId?: string
  raffles?: raffleMinType[]
}) {
  const raffleIds = raffles && raffles.map(r => r.id)
  const participantDataRes = trpc.useQuery(['user.getParticipantDataForMultipleRaffles', {
    raffleIds: raffleIds ?? [],
    userId: userId ?? ''
  }], {
    enabled: !!raffleIds && !!userId,
  })
  console.log({participantDataRes});
  
  const rafflesWithParticipantData = raffles?.map(r => {
    const participantData = participantDataRes?.data && participantDataRes.data.find(participant => participant.raffleId === r.id)
    return {
      ...r,
      participantData
    }
  })
  return rafflesWithParticipantData
}