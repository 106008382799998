

export function getPointsForBuyingTickets(ticketValueInSol: number) {
  const experiencePoints = ticketValueInSol * 10 // for every 1 sol spent, you get 10 points e.g. 10 sol = 100 points, 200 SOL = 2000 points
  const gems = ticketValueInSol * 100 // for every 1 sol spent, you get 100 gems e.g. 0.1 sol = 10 gems, 10 sol = 1000 gems

  return { experiencePoints, gems }
}

export function getPointsForRafflingActivity(valueInSol: number) {
  const experiencePoints = valueInSol * 1 // for every 1 sol raffled, you get 1 points e.g. 10 sol = 10 points, 200 SOL = 200 points
  const gems = valueInSol * 10 // for every 1 sol raffled, you get 10 gems e.g. 0.1 sol = 1 gems, 10 sol = 100 gems

  return { experiencePoints, gems }
}

export function getPointsForBuyingTicketsWithMultiplier(valueInSol: number, pointsMultiplier: number) {
  const result = getPointsForBuyingTickets(valueInSol)
  
  return {
    gems: result.gems * pointsMultiplier,
    experiencePoints: result.gems * pointsMultiplier
  }
}

export const PointsGoLiveDate = new Date('2023-10-13T15:37:00.000Z')

