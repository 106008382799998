import {
  Box,
  Button, Flex,
  GridItem,
  HStack, Image,
  Input, Stack,
  Text,
  useColorMode
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useAsyncFn } from 'react-use'
import { monetFeatureConfig } from '../../../config/monetFeatureConfig'
import { trpc } from '../../../utils/trpc'
import { formatFloatForDisplay } from '../../../utils/utils'
import { useSocialLink } from '../../admin/components/discord-link-ui/discordLinkHooks'
import { TwitterLinkMultiButton } from '../../admin/components/twitter-link-ui/TwitterLinkMultiButton'
import { useUser } from '../../common/auth/authHooks'
import { useParticipantDataForSingleRaffleId } from '../../rewards/hooks/useParticipant'
import {
  participantType, raffleMinType
} from '../../techRaffles/types'
import {
  ConnectDiscordBoxOnOverview
} from './BuyTicketsOnOverview'

type EnterRewardRaffleOnOverviewProps = {
  raffle: raffleMinType
  participantData?: participantType | null
  userId?: string
  variant: 'card' | 'table'
}

export const EnterRewardRaffleOnOverview: React.FC<
  EnterRewardRaffleOnOverviewProps
> = ({ raffle, participantData, userId, variant }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const wallet = useWallet()
  const [buttonLoadingText, setButtonLoadingText] = useState('Confirming...')
  const [ticketAmountToBuy, setTicketAmountToBuy] = useState(4)
  const { discordUsername, twitterUsername } = useSocialLink()
  const { data: user, isLoading } = useUser()
  const participantDataRes = useParticipantDataForSingleRaffleId({
    userId: user?.id ?? userId,
    raffleId: raffle.id,
  })

  const enterRewardRaffleMut = trpc.useMutation(['raffle.enterRewardRaffle'], {
    onSuccess: async (data) => {},
    onError: (error) => {
      console.log('error buy ticket request', error)
      toast.error(error.message)
    },
  })

  const [enterRewardRaffleRes, enterRewardRaffle] = useAsyncFn(async () => {
    if (!wallet.publicKey || !wallet.signTransaction || !raffle) return

    if (!monetFeatureConfig.ticketSale) {
      toast('Ticket sale disabled')
      return
    }

    console.log('buying ticket starting')

    try {
      setButtonLoadingText('Creating transaction...')
      const buyTicketResData = await enterRewardRaffleMut.mutateAsync({
        raffleId: raffle.id,
        ticketCount: ticketAmountToBuy,
        userWalletBase58: wallet.publicKey.toBase58(),
      })

      participantDataRes.refetch()

      toast(
        `Entered with ${ticketAmountToBuy} ticket${
          ticketAmountToBuy > 1 ? 's' : ''
        }`,
        {
          duration: 6000,
          icon: '🎉',
          style: {
            fontSize: '1.2rem',
            fontWeight: 600,
          },
        }
      )
    } catch (e: any) {
      console.error('Error in buying tickets', e)
      toast.error('Entering failed')
    }
  }, [wallet, ticketAmountToBuy, raffle.id])

  if (!wallet.publicKey) return <></>

  const participant = participantDataRes.data ?? participantData

  return (
    <Box padding={variant === 'card' ? '.7rem' : 0}>
      {!!wallet.publicKey &&
        ((raffle.type === 'WHITELIST' || raffle.holdersOnly) &&
        !discordUsername ? (
          <>
            {raffle.type === 'WHITELIST' && (
              <ConnectDiscordBoxOnOverview text='Please connect your Discord Account for whitelist raffles. This allows the raffle creator to submit your spots.' />
            )}
            {raffle.type !== 'WHITELIST' && raffle.holdersOnly && (
              <ConnectDiscordBoxOnOverview text='Please connect your Discord Account for holder-only raffles. This allows us to check your holder status.' />
            )}
          </>
        ) : raffle.needsSocial && !twitterUsername ? (
          <Stack direction={{ base: 'column', md: 'row' }} gap={3}>
            <TwitterLinkMultiButton />
          </Stack>
        ) : (
          <Stack w='100%'>
            <HStack alignItems='center' justifyContent={'center'} w='100%'>
              <Box w='100%'>
                <Flex
                  w='100%'
                  alignItems={'center'}
                  background={
                    isDarkMode ? 'rgba(255, 255, 255, 0.06)' : '#fefefe'
                  }
                  h={variant === 'card' ? '2.6rem' : '2.4rem'}
                  color={isDarkMode ? '#fff' : '#232323'}
                  rounded='full'
                  border='solid 1px'
                  borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
                >
                  <Button
                    bg='transparent'
                    color={isDarkMode ? 'white' : 'black'}
                    ml='1'
                    onClick={() => {
                      setTicketAmountToBuy(
                        ticketAmountToBuy > 1 ? ticketAmountToBuy - 1 : 1
                      )
                    }}
                  >
                    -
                  </Button>
                  <Input
                    border='none'
                    color={isDarkMode ? '#fff' : '#232323'}
                    background={'transparent'}
                    rounded='full'
                    maxW={
                      variant === 'table'
                        ? ['50px', '50px', '50px', '50px', '50px', '80px']
                        : '120px'
                    }
                    type='number'
                    min={1}
                    max={
                      raffle.maxTicketsPerUser
                        ? raffle.maxTicketsPerUser
                        : undefined
                    }
                    placeholder='No of Tickets'
                    textAlign='center'
                    padding={0}
                    minW='30px'
                    value={ticketAmountToBuy}
                    onChange={(e) => {
                      setTicketAmountToBuy(Number(e.target.value))
                    }}
                  ></Input>
                  <Button
                    mr='1'
                    bg='transparent'
                    color={isDarkMode ? 'white' : 'black'}
                    onClick={() => {
                      setTicketAmountToBuy(ticketAmountToBuy + 1)
                    }}
                  >
                    +
                  </Button>
                </Flex>
              </Box>
              <Box>
                <Button
                  variant={isDarkMode ? 'primaryDark' : 'primary'}
                  w='100%'
                  display='flex'
                  justifyContent={'center'}
                  alignItems='center'
                  px={variant === 'card' ? 3 : 2}
                  py='2'
                  height={variant === 'card' ? '2.8rem' : '2.4rem'}
                  minWidth={'130px'}
                  borderRadius='full'
                  gap='1'
                  isLoading={enterRewardRaffleRes.loading}
                  onClick={() => enterRewardRaffle()}
                >
                  <Text
                    textAlign={'right'}
                    color={isDarkMode ? '#333' : 'white'}
                    fontWeight='bold'
                  >
                    Enter
                  </Text>
                  <Flex justifyContent={'center'} py='1' px='2' gap='1'>
                    <Image src='/images/rewards/GemsShine.svg' />
                    <Text
                      color={isDarkMode ? '#333' : 'white'}
                      fontWeight='bold'
                    >
                      {formatFloatForDisplay(
                        raffle.ticketPrice * ticketAmountToBuy
                      )}
                    </Text>
                  </Flex>
                </Button>
              </Box>
            </HStack>
            {variant === 'card' && (
              <RewardRaffleStats participant={participant} />
            )}
          </Stack>
        ))}
    </Box>
  )
}

export const RewardRaffleStats: React.FC<{
  participant?: participantType | null
}> = ({ participant }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  return (
    <>
      {participant?.ticketsBought && participant.ticketsBought > 0 && (
        <GridItem colSpan={12}>
          <Text
            textAlign={'center'}
            fontSize={['0.9rem', '1rem']}
            fontWeight={600}
            color={isDarkMode ? '#dbd8d8' : '#6b6b6b'}
            mt='1rem'
          >
            👏 You have {participant.ticketsBought} entr
            {participant.ticketsBought === 1 ? 'y' : 'ies'} 👏
          </Text>
        </GridItem>
      )}
    </>
  )
}
