import { FC, useMemo } from 'react'
import {
  Stack,
  Text,
  HStack,
  Image,
  useColorMode,
  Skeleton,
  StackProps,
  Tooltip,
  ImageProps,
} from '@chakra-ui/react'
import { useUserByWalletInRouter } from '../../techRaffles/hooks/user'
import { useProjectBySlug } from '../../techRaffles/hooks/project'
import { Badge } from '@prisma/client'

export const BadgesCard: FC<{
  h: StackProps['h']
  stackProps: StackProps
  variant?: string
  badges?: Badge[]
  isLoading?: boolean
}> = ({ h, stackProps, badges, isLoading }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  return (
    <>
      {isLoading ? (
        <Skeleton w='full' h={h} borderRadius='20px' />
      ) : badges && badges.length > 0 && (
        <HStack
          width='full'
          border='solid'
          borderRadius='20px'
          justifyContent='space-between'
          alignItems='center'
          paddingTop='5'
          paddingBottom='5'
          paddingLeft='10'
          paddingRight='10'
          borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
          bgColor={isDarkMode ? 'cardBlack' : '#FAFAFA'}
          h={h}
          {...stackProps}
        >
          <Text color='#7B7B7B'>Badges</Text>
          <HStack>
            <BadgesList
              imageW='60px'
              m={(badges ?? []).length < 2 ? '0' : '-10px !important'}
              badges={badges}
            ></BadgesList>
          </HStack>
        </HStack>
      )}
    </>
  )
}

export const BadgesList: FC<{
  badges?: Badge[]
  imageW?: ImageProps['w']
  m?: ImageProps['m']
}> = ({ badges, imageW, m }) => {
  return (
    <>
      {(badges ?? []).map((badge) => {
        return (
          <Tooltip label={badge.description}>
            <Image
              w={imageW}
              key={badge.id}
              m={m ? m : '-10px !important'}
              src={badge.imageUrl}
            />
          </Tooltip>
        )
      })}
    </>
  )
}
